<template>
  <div>
    <!-- <Loader /> -->
    <!-- <Spinner /> -->
    <div class="wrapper">
      <!-- Sidebar  -->
      <SmallSidebar
        v-if="$route.name === 'dashboard.home-5'"
        :logo="SmallSidebarLogo"
        @toggle="sidebarMini"
      />
      <Sidebar
        :items="verticalMenu"
        :logo="logo"
        :onlyLogo="onlyLogo"
        :onlyLogoText="onlyLogoText"
        @toggle="sidebarMini"
        
        :sidebarGroupTitle="sidebarGroupTitle"
      />
      <!-- TOP Nav Bar -->
      <DefaultNavBar
        title="Dashboard"
        :homeURL="{ name: 'dashboard.home' }"
        :sidebarGroupTitle="sidebarGroupTitle"
        @toggle="sidebarMini"
        :logo="logo"
        class="d-block"
      >
      </DefaultNavBar>
      <!-- TOP Nav Bar END -->
      <div id="content-page" class="content-page" :class="getBreadcrumb !== null ? 'mt-5' : ''">
        <transition
          name="router-anim"
          :enter-active-class="`animated ${animated.enter}`"
          mode="out-in"
          :leave-active-class="`animated ${animated.exit}`"
        >
          <router-view />
        </transition>
      </div>
      <LayoutFixRightSide v-if="$route.name === 'dashboard.home-1'" />
    </div>
    <LayoutFooter />
  </div>
</template>
<script>
import { core } from "../config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import Loader from "../components/core/loader/Loader";
import Spinner from "../components/core/loader/Spinner";
import Sidebar from "../components/core/sidebars/Sidebar";
import DefaultNavBar from "../components/core/navbars/DefaultNavBar";
import SideBarItems from "../FackApi/json/SideBar";
import profile from "../assets/images/2.png";
import loader from "../assets/images/2.png";
import Lottie from "../components/core/lottie/Lottie";
import WhiteLogo from "../assets/images/2.png";
import SmallSidebar from "../components/core/sidebars/SmallSidebar";
import BreadCrumb from "../components/core/breadcrumbs/BreadCrumb";
import LayoutFooter from "./Components/LayoutFooter";
import csrf from "@/apis/Csrf";

export default {
  name: "VerticleLayout",
  components: {
    LayoutFooter,
    Lottie,
    Loader,
    Spinner,
    Sidebar,
    DefaultNavBar,
    SmallSidebar,
    BreadCrumb
  },
  mounted() {
    this.layoutSetting(this.$route.name);
  },
  watch: {
    $route: function(to, from) {
      this.layoutSetting(to.name);
    },
  },
  // sidebarTicket
  data() {
    return {
      animated: { enter: "fadeInUp", exit: "fadeOut" },
      verticalMenu: SideBarItems,
      userProfile: profile,
      onlyLogo: false,
      onlyLogoText: false,
      sidebarGroupTitle: true,
      logo: loader,
      rtl: false,
      SmallSidebarLogo: WhiteLogo,
      
      notBookmarkRouts: [
        "dashboard.home",
      ]
    };
  },
  methods: {
    layoutSetting(routeName) {
      this.onlyLogo = true;
      this.onlyLogoText = true;
      this.sidebarGroupTitle = true;
      switch (routeName) {
        case "dashboard.home-5":
          this.onlyLogoText = true;
          this.onlyLogo = false;
          break;
        case "dashboard.home-6":
          this.logo = WhiteLogo;
          this.onlyLogo = true;
          this.onlyLogoText = false;
          this.sidebarGroupTitle = false;
          break;
        default:
          break;
      }
    },
    changeLogo(e) {
      this.logo = e;
    },
    sidebarMini() {
      core.triggerSet();
    },
    async logout() {
      // This is just for demo Purpose. If user clicks on logout -> redirect
      try {
        await csrf.getCookie();
        await this.$store.dispatch("auth/logout");
        this.$store.commit("auth/LOGOUT");
        localStorage.removeItem("auth");
        this.$router.push({ name: "auth1.login" });
      } catch (error) {}
    },
    routerAnimationChange(e) {
      this.animated = e;
    },
  },
  async created() {
    let profile = await this.$store.dispatch("profile/fetchUserProfile");
  },
  computed: {
    ...mapGetters({
      getBreadcrumb: 'getBreadcrumb'
    })
  },
};
</script>
<style>
@import url("../assets/css/custom.css");
@import url("../assets/css/PriceSlider.css");
  .tooltip{
    opacity: 1 !important;
  }
</style>

<template>
  <b-collapse
    tag="ul"
    :class="className"
    :visible="open"
    :id="idName"
    :accordion="accordianName"
  >
    <li v-for="(item, index) in items" :key="index">
      <i v-if="item.is_heading && hideListMenuTitle" class="ri-subtract-line" />
      <template v-if="item.help">
        <hr class="mt-5" />
      </template>
      <template v-if="item.profile"> </template>
      <template v-if="item.logout">
        <a @click="logout" class="iq-waves-effect btn btn-link">
          <i class="ri-login-box-line" />
          <span>Cerrar sesión</span>
        </a>
      </template>
      <template else>
        <router-link
          :to="item.link ? item.link : ''"
          v-if="permisos(item.slug)"
          class="iq-waves-effect"
          v-b-toggle="item.name"
        >
          <i :class="item.icon" v-if="item.is_icon_class" />
          <template v-else v-html="item.icon"> </template> 
          <span>{{ $t(item.name) }}</span>
          <i
            v-if="item.children"
            class="ri-arrow-right-s-line iq-arrow-right"
          />
          <small
            v-html="item.append"
            v-if="hideListMenuTitle"
            :class="item.append_class"
          />
          <b-badge v-if="item.purchase" pill variant="primary"
            ><span class="m-1">{{date}}</span></b-badge
          >
          <b-badge v-if="item.order" pill variant="primary"
            ><span class="m-1">{{order}}</span></b-badge
          >
        </router-link>
        <List
          v-if="item.children"
          :items="item.children"
          :sidebarGroupTitle="hideListMenuTitle"
          :idName="item.name"
          :accordianName="`sidebar-accordion-${item.class_name}`"
          :className="`iq-submenu ${item.class_name}`"
        />
      </template>
    </li>
  </b-collapse>
</template>
<script>
import List from "./CollapseMenu";
import { core } from "@/config/pluginInit";
import csrf from "@/apis/Csrf";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "List",
  props: {
    items: Array,
    className: { type: String, default: "iq-menu" },
    open: { type: Boolean, default: false },
    idName: { type: String, default: "sidebar" },
    accordianName: { type: String, default: "sidebar" },
    sidebarGroupTitle: { type: Boolean, default: true },
  },
  components: {
    List,
  },
  async created(){
    await this.purchaseData()
    await this.ordersData()
  },

  computed: {
    hideListMenuTitle() {
      return this.sidebarGroupTitle;
    },
    ...mapGetters({
      permits: "auth/getPermissionsUser",
      date: "purchase/getPurchaseCreate",
      order: "orders/getLisOrderCreate"

    })
  },
  methods: {
    ...mapActions({
      purchaseData: "purchase/listPurchaseRequests",
      ordersData : "orders/listPurchaseOrders"
    }),
    activeLink(item) {
      return core.getActiveLink(item, this.$route.name);
    },
    async logout() {
      try {
        await csrf.getCookie();
        await this.$store.dispatch("auth/logout");
        this.$store.commit("auth/LOGOUT");
        localStorage.removeItem("auth");
        this.$router.push({ name: "auth1.login" });
      } catch (error) {}
    },
    permisos(slug) {
      const permits = this.permits;
      const res = permits.find((element) => element === slug);
      if (slug === "user") {
        return true;
      } else if (res === slug) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

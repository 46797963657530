<template>
  <div>
    <MiniFooter>
      <template v-slot:left>
       <span>Colegio Santa Joaquina de Vedruna © {{ new Date().getFullYear()}}.</span> <small>v{{ getVersion }}</small>
      </template>
      <template v-slot:right>
        Sitio web desarrollado por <a href="https://nextstation.cl/" target="_blank">NextStation.cl</a>
      </template>
    </MiniFooter>
  </div>
</template>
<script>
import MiniFooter from '../../components/core/footer/MiniFooter'
import {mapGetters} from 'vuex'
export default {
  name: 'LayoutFooter',
  components: {
    MiniFooter
  },
  mounted () {
  },
  data () {
    return {
    }
  },
  computed:{
     ...mapGetters({
      getVersion : "getVersion"
    })
  }
}
</script>

<template>
  <b-col cols="12">
      <b-row class="d-flex align-items-center">
        <b-col md="9">
          <div class="d-flex align-items-center">
            <h4 class="mb-0 p-3 ml-2">{{ name }}</h4>
            <b-breadcrumb :items="itemBread" class="bg-transparent mb-0 mt-1" />
          </div>
        </b-col>
      </b-row>
  </b-col>
</template>
<script>
export default {
  name: 'BreadCrumb',
  props: {
    name: null,
    itemBread: null
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<template>
  <div class="iq-sidebar" >
    <div class="iq-sidebar-logo d-flex justify-content-between">
      <router-link :to="{name: 'dashboard.home'}">
        <img :src="logo" v-if="onlyLogo" class="img-fluid ml-3" alt="logo">
        <span class="ml-2" v-if="onlyLogoText"><h5 class="font">{{ appName }}</h5></span>
      </router-link>
      <div class="iq-menu-bt-sidebar" v-if="toggleButton">
        <div class="iq-menu-bt align-self-center">
          <div class="wrapper-menu" @click="miniSidebar">
            <div class="main-circle"><i class="ri-arrow-left-s-line"></i></div>
            <div class="hover-circle"><i class="ri-arrow-right-s-line"></i></div>
          </div>
        </div>
      </div>
    </div>
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu" :class="horizontal ? 'd-xl-none' : ''">
        <CollapseMenu :items="items" :open="true" :horizontal="horizontal" :sidebarGroupTitle="sidebarGroupTitle"/>
      </nav>
      <div class="p-3"></div>
    </div>
  </div>
  <!-- TOP Nav Bar -->
</template>

<script>
import CollapseMenu from '../menus/CollapseMenu'
import { APPNAME } from '../../../config/pluginInit'
import csrf from '@/apis/Csrf'
export default {
  name: 'Sidebar',
  props: {
    homeURL: { type: Object, default: () => ({ name: 'dashboard.home' }) },
    items: { type: Array },
    logo: { type: String, default: require('@/assets/images/2.png') },
    horizontal: { type: Boolean },
    toggleButton: { type: Boolean, default: true },
    logoShow: { type: Boolean, default: true },
    onlyLogo: { type: Boolean, default: false },
    onlyLogoText: { type: Boolean, default: false },
    sidebarGroupTitle: { type: Boolean, default: true }
  },
  components: {
    CollapseMenu
  },
  mounted () {
  },
  methods: {
    miniSidebar () {
      this.$emit('toggle')
    },
    async logout () {
      try {
        await csrf.getCookie()
        await this.$store.dispatch('auth/logout')
        this.$store.commit('auth/LOGOUT')
        localStorage.removeItem('auth')
        this.$router.push({ name: 'auth1.login' })
      } catch (error) {}
    }
  },
  data () {
    return {
      appName: APPNAME
    }
  }
}
</script>
<style scope>
  .font{
    text-transform: uppercase !important;
  }
</style>

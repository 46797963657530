import { render, staticRenderFns } from "./BreadCrumb.vue?vue&type=template&id=67eb8aa0&"
import script from "./BreadCrumb.vue?vue&type=script&lang=js&"
export * from "./BreadCrumb.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
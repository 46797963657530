<template>
  <!-- TOP Nav Bar -->
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom">
      <b-row class="d-flex align-items-center">
        <b-col md="9" class="p-2">
          <div class="d-flex align-items-center">
            <b-button
              v-if="$route.meta.layout !== 'two-sidebar'"
              class="iq-bg-primary mt-0 ml-3 d-xl-none"
              variant="none"
              @click="miniSidebar"
              ><i class="ri-arrow-left-s-line h5"></i>
            </b-button>
            <BreadCrumb :itemBread="getBreadcrumb" />
          </div>
        </b-col>
        <b-col
          md="3"
          class="ml-auto"
          v-if="
            getBreadcrumb[1]
              ? getBreadcrumb[1].text === 'Ayuda'
                ? true
                : false
              : ''
          "
        >
          <b-link :href="documento" target="_blank" class="btn btn-primary"
            ><i class="ri-download-2-fill"></i>
            <small>Descargar manual de usuario</small></b-link
          >
        </b-col>
      </b-row>
    </div>
  </div>
  <!-- TOP Nav Bar END -->
</template>
<script>
import { APPNAME } from "../../../config/pluginInit";
import { mapGetters } from "vuex";
import CollapseMenu from "../menus/CollapseMenu";
import BreadCrumb from "@/components/core/breadcrumbs/BreadCrumb";

export default {
  name: "DefaultNavBar",
  props: {
    homeURL: { type: Object },
    logo: { type: String, default: require("../../../assets/images/2.png") },
    horizontal: { type: Boolean, default: false },
    items: { type: Array },
    sidebarGroupTitle: { type: Boolean, default: true },
  },
  mounted() {
    if (!this.horizontal) {
      document.addEventListener("click", this.closeSearch, true);
    }
  },
  components: {
    CollapseMenu,
    BreadCrumb,
  },
  computed: {
    ...mapGetters({
      getBreadcrumb: "getBreadcrumb",
    }),
  },
  data() {
    return {
      appName: APPNAME,
      globalSearch: "",
      showSearch: false,
      showMenu: false,
      documento: require("@/assets/doc/manual.pdf"),
      itemsBread: [
        {
          html: '<i class="ri-home-2-line"></i> Inicio',
          to: "/",
        },
      ],
    };
  },
  methods: {
    miniSidebar() {
      this.$emit("toggle");
    },
    openSearch() {
      this.showSearch = true;
      this.showMenu = "iq-show";
      this.globalSearch = "";
      if (document.getElementById("searchbox-datalink") !== null) {
        document
          .getElementById("searchbox-datalink")
          .classList.add("show-data");
      }
    },
    closeSearch(event) {
      const classList = event.target.classList;
      if (
        !classList.contains("searchbox") &&
        !classList.contains("search-input")
      ) {
        this.removeClass();
      }
    },
    removeClass() {
      this.showSearch = false;
      this.showMenu = "";
      this.globalSearch = "";
      if (
        document.getElementById("searchbox-datalink") !== null &&
        document.getElementById("searchbox-datalink") !== undefined
      ) {
        document
          .getElementById("searchbox-datalink")
          .classList.remove("show-data");
      }
    },
  },
};
</script>

<style scoped lang="scss">
// .collapse-menu{
//   @media (min-width:1300px) {
//     display: none;
//   }
// }
// .iq-sidebar-menu .iq-menu.hover-menu{
//   display: flex;
//   @media (max-width:1299px){
//     display: none !important;
//   }
// }
</style>
